import React, { memo } from 'react'
import { useNavigate } from "react-router-dom"

import { FooterWrapper } from './styled'

import ewm from '@/assets/img/ewm.png'
import partner1 from '@/assets/img/partner1.png'
import partner2 from '@/assets/img/partner2.png'
import partner3 from '@/assets/img/partner3.png'
import partner4 from '@/assets/img/partner4.png'

const Footer = memo(({isShowMore = true}) => {
  const navigate = useNavigate()

  const handleNavigatePrivacy = () => {
    navigate('/privacy')
  }

  const handleNavigateClause = () => {
    navigate('/clause')
  }

  return (
    <FooterWrapper>
      <div className="content wrap">
        <div className="item left">
          <h2>联系我们</h2>
          <div className="list">
            <div>联系地址: 上海市杨浦区政通路189号万达广场A栋12楼B21</div>
            <div>客服热线: 15959258269</div>
            <div>公司邮箱: caoyu@licaai.cn</div>
          </div>
        </div>
        <div className="item center">
          <h2>关注我们</h2>
          <div className='list'>
            <div>公众号: 梨卡营养</div>
            <div className='mini'>
              <span>小程序: </span>
              <img src={ewm} alt="" />
            </div>
          </div>
        </div>
        <div className="item right">
          <h2>合作伙伴</h2>
          <div className="list">
            <img src={partner1} alt="" />
            <img src={partner2} alt="" />
            <img src={partner3} alt="" />
            <img src={partner4} alt="" />
          </div>
        </div>
        {
          isShowMore && <div className='item privacy'>
          <h2>了解更多</h2>
          <div className="list">
            <div><span onClick={handleNavigateClause} className='sub'>梨卡相机-隐私政策</span></div>
            <div><span onClick={handleNavigatePrivacy} className='sub'>梨卡相机-用户协议</span></div>
          </div>
        </div>
        }
      </div>
      <a className='record' href="https://beian.miit.gov.cn/" rel='noreferrer' target="_blank">沪ICP备2023002894号-1</a >
    </FooterWrapper>
  )
})

export default Footer