import { memo } from "react";


import Card from '@/components/card'
import { useAnimation } from '@/hooks'

import TunnelImg from '@/assets/img/suidao1.png'

import { TunnelWrapper } from "./style";
import { useNavigate } from "react-router-dom";

const Tunnel = memo(() => {

  const cardRef = useAnimation()

  const navigate = useNavigate()

  const navigateTunnelApp = () => {
    navigate("/take_photo_usage_doc")
  }
  
  return (
    <TunnelWrapper className="wrap">
       <div className="content" ref={cardRef}>
          <div className="left inner-content">
            <Card title='中国电建中南院地质AI工程师' className='nutrition' />
            <div className='desc1'>国内首家实现并应用移动端大场景3D实景建模技术。融合计算机视觉、图像处理、三维建模和移动计算等多个学科的前沿技术，对隧道酮室等复杂工程场景的高效、精确的三维数字化重建，结合AI技术实现智能勘测，为隧道酮室等工程勘测领域带来革命性的变革，极大地提升勘测效率，降低成本，同时提高数据采集的灵活性和准确性。在魏家冲等交通洞项目落地中得到客户高度认可。</div>
          </div>
          <div className="right inner-content small-none">
            <img src={TunnelImg} alt="" onClick={navigateTunnelApp} />
          </div>
        </div>
    </TunnelWrapper>
  )
})

export default Tunnel