import React, { memo } from 'react'


import { MainWrapper } from './style'

import NutritionConsult from './c-cpns/nutrition-consult'
import NutritionManage from './c-cpns/nutrition-manage'
import ChangeCooperation from './c-cpns/cooperation'
import Tunnel from './c-cpns/tunnel'

const Main = memo(() => {
  return (
    <MainWrapper className='main'>
      <div className="main-img">
        <div className="cover">
          <div className="content">
            <h2 className='name'>零咔智能</h2>
            <div className='desc'>全球营养健康数智化先驱</div>
          </div>
        </div>
      </div>
      <div className="intro wrap">
        <div className="bottom desc" style={{padding: '0 15px'}}>
          <h1>公司理念</h1>
          <div className='detail'>
            <div>我们是一家初创公司</div>
            <div>致力于利用人工智能技术为您提供高效</div>
            <div>精准的食物营养识别和人工智能营养咨询师的服务</div>
          </div>
        </div>
      </div>
      <div className="list">
        <NutritionConsult />
        <NutritionManage />
        <ChangeCooperation />
        <Tunnel />
      </div>
    </MainWrapper>
  )
})

export default Main