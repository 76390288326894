import React, { memo } from 'react'
import { ManageWrapper } from './style'

import Card from '@/components/card'
import { useAnimation } from '@/hooks'
import earthImg from '@/assets/img/earth.png'


const NutritionManage = memo(() => {
  const cardRef = useAnimation()

  return (
    <ManageWrapper>
      <div className="content wrap"  ref={cardRef}>
        <div className="inner">
          <div className="left inner-content small-none">
            <img src={earthImg} alt="" />
          </div>
          <div className="right inner-content">
            <div>
              <Card title='智能识别营养管理'/>
              <div className="desc1">
                <div>已有专利《一种基干Yolov5-efficientnet的食物热量测算算法》</div>
                <div>100万+食物数据的营养大数据中台</div>
                <div>累计为1800+用户提供5000+次服务，识别500+食物种类的热量、脂肪、蛋白质、碳水化合物等多类营养元素</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ManageWrapper>
  )
})

export default NutritionManage